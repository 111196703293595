import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => {
  const [isActive, setisActive] = React.useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <h1><strong>{ siteTitle }</strong></h1>
          </Link>          

          <a
            onClick={() => {setisActive(!isActive)}}
            role="button"
            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>

        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/index/tape_ss_all_all">&nbsp;&nbsp;<b>子供用オムツ</b></Link>
            <Link className="navbar-item" to="/longtime/pants_m_all_all">&nbsp;&nbsp;<b>長時間・夜用</b></Link>
            <Link className="navbar-item" to="/toitore/pants_l_all_all">&nbsp;&nbsp;<b>トイトレ用</b></Link>
            <Link className="navbar-item" to="/premium/tape_sss_all_all">&nbsp;&nbsp;<b>プレミアム</b></Link>
            <Link className="navbar-item" to="/index/wipe_mizu_all_all">&nbsp;&nbsp;<b>おしりふき</b></Link>
            <Link className="navbar-item" to="/milk/milk_powder_can_big_all_all">&nbsp;&nbsp;<b>ミルク</b></Link>
            <Link className="navbar-item" to="/drink/baby_tea_pet_all_all">&nbsp;&nbsp;<b>ドリンク</b></Link>
            <Link className="navbar-item" to="/daily/softener_main_all_all">&nbsp;&nbsp;<b>日用品</b></Link>
            <Link className="navbar-item" to="/garbage/bag_garbage_ss_all_all">&nbsp;&nbsp;<b>オムツ処理</b></Link>
            <Link className="navbar-item" to="/hometown_tax_payment/tape_s_all_all">&nbsp;&nbsp;<b>ふるさと納税</b></Link>
            <Link className="navbar-item" to="/index/adult_pants_adult_ss_all_all">&nbsp;&nbsp;<b>大人用オムツ(介護用)</b></Link>
          </div>
        </div>

      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
