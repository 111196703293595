import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered is-small">
        <p>
          <Link to="/sitemap.html">サイトマップ</Link> &nbsp; <Link to="/about.html">運営者情報</Link> &nbsp; <Link to="/privacy-policy.html">プライバシーポリシー</Link>
        </p>
        <p>Copyright (C) 2017 オムツ1枚あたりの最安値 All Rights Reserved.</p>
      </div>
    </footer>			
  )
}

export default Footer
